<template>
  <q-card style="width: 600px; max-width: 60vw;height: 700px;">
    <q-bar class="bg-primary text-white">
      <q-icon name="fas fa-keyboard" />
      <div>
        排班项目选择
        <small class="q-ml-sm text-size-sm"> </small>
      </div>

      <q-space />

      <q-btn dense flat icon="close" v-close-popup>
        <q-tooltip>关闭</q-tooltip>
      </q-btn>
    </q-bar>

    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm">
        <!-- <q-input
          outlined
          class="col-2"
          dense
          v-model="FilterString"
          label="编码\名称"
          color="primary"
        />
        <q-btn
          color="primary"
          icon="search"
          @click="onLoadData"
          :loading="PdmBodycheckTaocanListLoading"
          style="height: 38px;"
        >
          查询
        </q-btn> -->
        <q-space />
        <!-- <q-btn color="primary" icon="download" style="height: 38px;" @click="onDownload" :loading="WxHomeimageListLoading">
              下载
            </q-btn>
            <q-btn color="primary" style="height: 38px" icon="fas fa-file-excel" :loading="PdmBodycheckTaocanListLoading" @click="onExportData">导出</q-btn> -->
        <!-- <q-btn color="primary" style="height: 38px" icon="note_add" :loading="PdmBodycheckTaocanListLoading" @click="onAdd">新增</q-btn> -->
      </div>
      <vxe-grid
      ref="xGrid"
        v-bind="gridOptions"
        :sort-config="{ multiple: true }"
        :custom-config="{ storage: true }"
        id="custom-config"
        :export-config="gridOptions.tableExport"
        :height="550"
        :loading="PdmBodycheckTaocanListLoading"
        highlight-current-row
        size="mini"
        @checkbox-change="checkboxChangeEvent"
        @checkbox-all="checkboxChangeEvent"
      >
        <template #toolbar_buttons id="custom-config"> </template>
        <template #operate="{ row }">
          <vxe-button
            flat
            color="primary"
            icon="fas  fa-edit"
            @click="onEdit(row)"
            >编辑</vxe-button
          >
          <vxe-button
            flat
            color="negative"
            icon="fas fa-trash"
            @click="onDelete(row)"
            >删除</vxe-button
          >
        </template>
      </vxe-grid>
    </q-card-section>

    <q-card-actions align="right" class="bg-white text-teal">
      <q-btn flat label="取消" v-close-popup />
      <q-btn flat label="确定" @click="onOK" />
    </q-card-actions>
  </q-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import * as BodycheckTaocan from "@/api/pdm/bodycheckTaocan";
export default {
  components: {},
  props: {},
  computed: {
    ...mapState("PdmBodycheckPaiban", [
      "PdmBodycheckPaibanShowDlg",
      "PdmBodycheckPaibanEntity",
      "PdmBodycheckPaibanList",
      "PaiBanData",
      "Years",
      "Month"
    ]),
    ...mapState("PdmBodycheckTaocan", [
      "PdmBodycheckTaocanList",
      "PdmBodycheckTaocanListLoading",
      "PdmBodycheckTaocanEntity",
      "PdmBodycheckTaocanShowDlg",
    ]),
  },
  data() {
    return {
      FilterString: "",
      gridCheckboxList:[],
      tablePage: {
        currentPage: 1,
        pageSize: 20,
        totalResult: 0,
        pageSizes: [15, 20, 50, 100, 5000],
      },
      gridOptions: {
        border: true,
        resizable: true,
        showOverflow: true,
        highlightHoverRow: true,
        align: "left",
        toolbarConfig: {
          perfect: true,
          print: true,
          zoom: true,
          custom: true,
          export: true,
          slots: {
            // 自定义工具栏模板
            buttons: "toolbar_buttons",
          },
        },
        //点击编辑必须配置
        // editConfig: {
        //   trigger: "click",
        //   mode: "cell",
        //   activeMethod: () => {
        //     if (this.lockData) return false;
        //     return true;
        //   },
        // },
        tableExport: {},
        columns: [
          { type: "checkbox", width: 50, align: "left",},
        //   {
        //     field: "HospID",
        //     title: "机构ID",
        //     sortable: true,
        //     align: "center",
        //     width: 160,
        //   },
          {
            field: "TaoCan_Name",
            title: "套餐名称",
            sortable: true,
            align: "left",
            width: 300,
          },
        //   {
        //     title: "操作",
        //     width: 200,
        //     slots: { default: "operate" },
        //     align: "center",
        //     fixed: "right",
        //   },
          //   {
          //     field: "CatName",
          //     title: "类别",
          //     sortable: true,
          //     editRender: { name: "input" },//点击编辑必须配置
          //     slots: { edit: "CatName" },//点击编辑必须配置
          //     align: "left",
          //     width: 140,
          //   },
        ],
        // data: this.PdmBodycheckTaocanList,
        data: null,
      },
    };
  },
  mounted() {
    this.onLoadData();
  },
  methods: {
    ...mapActions("PdmBodycheckPaiban", [
      "actUpdatePdmBodycheckPaiban",
      "actGetPdmBodycheckPaibanList",
    ]),
    ...mapActions("PdmBodycheckTaocan", [
      "actGetPdmBodycheckTaocanListByPage",
      "actCreatePdmBodycheckTaocan",
      "actEditPdmBodycheckTaocanData",
      "actEditPdmBodycheckTaocan",
      "actDeletePdmBodycheckTaocan",
    ]),
    onOK() {
      this.actUpdatePdmBodycheckPaiban({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        ListInfo: this.PaiBanData,
        TaoCanList:this.gridCheckboxList,
        Years: this.Years,
        Month: this.Month,
      }).then((res) => {
        if (res.Code == 0) {
          //setTimeout(()=> {
          //this.$emit("onLoadData");
          //}, 500);
          this.$emit("onLoadData");
          this.msg.success(res.Message);
        } else {
          this.msg.error(res.Message);
        }
      });
    },
    onLoadData() {
      BodycheckTaocan.GetPDM_BodyCheck_TaoCanIsDelList({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        FilterString: this.FilterString,
        PageIndex: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize,
      })
        .then((res) => {
          this.gridOptions.data = res.Data;
          this.tablePage.totalResult = res.Count;
        })
        .finally(() => {
          this.$store.commit(
            "PdmBodycheckTaocan/setPdmBodycheckTaocanListLoading",
            false
          );
          //this.PdmBodycheckTaocanListLoading = false;
        });
    },
    checkboxChangeEvent(){
        var grid=this.$refs.xGrid;
        var gridCheckbox=grid.getCheckboxRecords();
        
        this.gridCheckboxList=gridCheckbox;

        console.log("gridCheckboxList==>",this.gridCheckboxList);
       
    }
  },
};
</script>
