<template>
  <q-card flat>
    <q-card-section class="q-pa-sm">
      <div class="row ">
        <div class="col-3 justify-center">
          <div class="row justify-center">
            <q-date
              minimal
              :disable="PdmBodycheckPaibanListLoading"
              v-model="PaiBanOtherData"
              @update:model-value="onclickDay"
              @navigation="onclickDate"
            />
          </div>
        </div>
        <div class="col-9">
          <div class="row q-gutter-sm q-mb-sm">
            <!-- <div>
              <q-input
                v-model.number="modelYears"
                type="number"
                outlined
                label="年份"
                style="max-width: 200px"
                dense
              />
            </div>
            <div>
              <q-input
                v-model.number="modelMonth"
                type="number"
                outlined
                label="月份"
                style="max-width: 200px"
                dense
              />
            </div> -->
            <q-input
              outlined
              class="col-2"
              dense
              v-model="FilterString"
              label="套餐名称"
              color="primary"
            />
            <q-btn
              color="primary"
              icon="search"
              @click="onLoadData"
              :loading="PdmBodycheckPaibanListLoading"
              style="height: 38px;"
            >
              查询
            </q-btn>
            <q-btn
              color="primary"
              @click="onCreateDate(1)"
              :loading="PdmBodycheckPaibanListLoading"
              style="height: 38px;"
            >
              生成排班
            </q-btn>
            <q-btn
              color="primary"
              @click="onClickItem"
              :loading="PdmBodycheckPaibanListLoading"
              style="height: 38px;"
            >
              选择套餐
            </q-btn>
            <q-space />
            <!-- <q-btn color="primary" icon="download" style="height: 38px;" @click="onDownload" :loading="WxHomeimageListLoading">
              下载
            </q-btn>
            <q-btn color="primary" style="height: 38px" icon="fas fa-file-excel" :loading="PdmBodycheckPaibanListLoading" @click="onExportData">导出</q-btn> -->
            <!-- <q-btn
          color="primary"
          style="height: 38px"
          icon="note_add"
          :loading="PdmBodycheckPaibanListLoading"
          @click="onAdd"
          >新增</q-btn
        > -->
          </div>
          <vxe-grid
            v-bind="gridOptions"
            :sort-config="{ multiple: true }"
            :custom-config="{ storage: true }"
            id="custom-config"
            :export-config="gridOptions.tableExport"
            :height="$store.getters.maxPageHeight - 140"
            :loading="PdmBodycheckPaibanListLoading"
            highlight-current-row
            size="mini"
          >
            <template #toolbar_buttons id="custom-config"> </template>
            <template #PaiBan_IsDelName="{ row }">
              <q-badge :color="row.PaiBan_IsDel == 0 ? 'positive' : 'negative'">
                {{ row.PaiBan_IsDelName }}
              </q-badge>
            </template>
            <template #operate="{ row }">
              <!-- <vxe-button
            flat
            color="primary"
            icon="fas  fa-edit"
            @click="onEdit(row)"
            >编辑</vxe-button
          >
          <vxe-button
            flat
            color="negative"
            icon="fas fa-trash"
            @click="onDelete(row)"
            >删除</vxe-button
          > -->
              <vxe-button flat color="negative" @click="onIsDel(row)">{{
                row.PaiBan_IsDel == 0 ? "禁用" : "启用"
              }}</vxe-button>
            </template>
          </vxe-grid>
          <vxe-pager
            perfect
            size="mini"
            :page-sizes="tablePage.pageSizes"
            v-model:current-page="tablePage.currentPage"
            v-model:page-size="tablePage.pageSize"
            :total="tablePage.totalResult"
            :layouts="[
              'PrevPage',
              'JumpNumber',
              'NextPage',
              'FullJump',
              'Sizes',
              'Total',
            ]"
            @page-change="handlePageChange"
          >
          </vxe-pager>
        </div>
      </div>
    </q-card-section>
  </q-card>

  <q-dialog v-model="pdmBodycheckPaibanshowDlg" persistent>
    <BodycheckPaibanEdit @onLoadData="onLoadData" />
  </q-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import XEUtils from "xe-utils";
import dlg from "@/config/dialog";
import BodycheckPaibanEdit from "./bodycheckPaibanEdit.vue";
import * as BodycheckPaiban from "@/api/pdm/bodycheckPaiban.js";
export default {
  components: {
    BodycheckPaibanEdit,
  },
  computed: {
    ...mapState("PdmBodycheckPaiban", [
      "PdmBodycheckPaibanList",
      "PdmBodycheckPaibanListLoading",
      "PdmBodycheckPaibanEntity",
      "PdmBodycheckPaibanShowDlg",
    ]),

    pdmBodycheckPaibanshowDlg: {
      get() {
        return this.PdmBodycheckPaibanShowDlg;
      },
      set(val) {
        console.log(val);
        this.$store.commit(
          "PdmBodycheckPaiban/setPdmBodycheckPaibanShowDlg",
          val
        );
      },
    },
  },
  data() {
    return {
      PaiBanDate: [],
      PaiBanOtherData: [],
      FilterString: "",
      modelYears: new Date().getFullYear(),
      modelMonth: new Date().getMonth() + 1,
      tablePage: {
        currentPage: 1,
        pageSize: 50,
        totalResult: 0,
        pageSizes: [15, 20, 50, 100, 5000],
      },
      gridOptions: {
        border: true,
        resizable: true,
        showOverflow: true,
        highlightHoverRow: true,
        align: "left",
        toolbarConfig: {
          perfect: true,
          print: true,
          zoom: true,
          custom: true,
          export: true,
          slots: {
            // 自定义工具栏模板
            buttons: "toolbar_buttons",
          },
        },
        //点击编辑必须配置
        // editConfig: {
        //   trigger: "click",
        //   mode: "cell",
        //   activeMethod: () => {
        //     if (this.lockData) return false;
        //     return true;
        //   },
        // },
        tableExport: {},
        columns: [
          { type: "seq", width: 50, align: "center" },
          {
            field: "TaoCan_Name",
            title: "套餐名称",
            sortable: true,
            align: "left",
            width: 160,
          },
          {
            field: "PaiBan_Str",
            title: "排班日期",
            sortable: true,
            align: "center",
            width: 160,
          },
          {
            field: "PaiBan_Years",
            title: "年份",
            sortable: true,
            align: "center",
            width: 100,
          },
          {
            field: "PaiBan_Month",
            title: "月份",
            sortable: true,
            align: "center",
            width: 80,
          },
          {
            field: "PaiBan_Week",
            title: "周",
            sortable: true,
            align: "center",
            width: 160,
          },
          {
            field: "PaiBan_IsDelName",
            title: "启用/禁用",
            sortable: true,
            slots: { default: "PaiBan_IsDelName" },
            align: "center",
            width: 100,
          },
          {
            title: "操作",
            width: 100,
            slots: { default: "operate" },
            align: "center",
            fixed: "right",
          },
          //   {
          //     field: "CatName",
          //     title: "类别",
          //     sortable: true,
          //     editRender: { name: "input" },//点击编辑必须配置
          //     slots: { edit: "CatName" },//点击编辑必须配置
          //     align: "left",
          //     width: 140,
          //   },
        ],
        // data: this.PdmBodycheckPaibanList,
        data: null,
      },
    };
  },
  mounted() {
    this.onLoadData();
    this.onCreateDate(0);
  },
  methods: {
    ...mapActions("PdmBodycheckPaiban", [
      "actGetPdmBodycheckPaibanListByPage",
      "actCreatePdmBodycheckPaiban",
      "actEditPdmBodycheckPaibanData",
      "actEditPdmBodycheckPaiban",
      "actDeletePdmBodycheckPaiban",
    ]),
    onLoadData() {
      this.actGetPdmBodycheckPaibanListByPage({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        FilterString: this.FilterString,
        Years: this.modelYears,
        Month: this.modelMonth,
        PageIndex: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize,
      })
        .then((res) => {
          this.gridOptions.data = res.Data;
          this.tablePage.totalResult = res.Count;
        })
        .finally(() => {
          this.$store.commit(
            "PdmBodycheckPaiban/setPdmBodycheckPaibanListLoading",
            false
          );
          //this.PdmBodycheckPaibanListLoading = false;
        });
    },
    onClickItem() {
      if (this.PaiBanOtherData.length <= 0) {
        dlg.alert("请先生成排班");
        return;
      }
      this.$store.commit("PdmBodycheckPaiban/setYears", this.modelYears);
      this.$store.commit("PdmBodycheckPaiban/setMonth", this.modelMonth);
      this.$store.commit("PdmBodycheckPaiban/setPaiBanData", this.PaiBanDate);
      this.$store.commit(
        "PdmBodycheckPaiban/setPdmBodycheckPaibanShowDlg",
        true
      );
    },
    onclickDay(val, val2, val3) {
      console.log("val==>", val);
      console.log("val2==>", val2);
      console.log("val3==>", val3);
      this.$store.commit(
        "PdmBodycheckPaiban/setPdmBodycheckPaibanListLoading",
        true
      );
      BodycheckPaiban.ClickDay({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        ListInfo: this.PaiBanDate,
        Years: this.modelYears,
        Month: this.modelMonth,
        Reason: val2,
        DetailsDate: val3.year + "/" + val3.month + "/" + val3.day,
      })
        .then((res) => {
          if (res.Code == 0) {
            this.PaiBanDate = res.Data;
            this.PaiBanOtherData = res.OtherData;
            console.log("排版时间",this.PaiBanOtherData);
          } else {
            dlg.alert(res.Message);
          }
        })
        .finally(() => {
          this.$store.commit(
            "PdmBodycheckPaiban/setPdmBodycheckPaibanListLoading",
            false
          );
        });
    },
    onclickDate(val) {
      this.modelYears = val.year;
      this.modelMonth = val.month;
    },
    onCreateDate(val) {
      BodycheckPaiban.CreateDate({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Years: this.modelYears,
        Month: this.modelMonth,
        CreateDateType:val
      }).then((res) => {
        if (res.Code == 0) {
          this.PaiBanDate = res.Data;
          this.PaiBanOtherData = res.OtherData;
        } else {
          dlg.alert(res.Message);
        }
      });
    },
    onCreate() {
      this.actCreatePdmBodycheckPaiban({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Years: this.modelYears,
        Month: this.modelMonth,
      }).then((res) => {
        if (res.Code == 0) {
          this.onLoadData();
        } else {
          dlg.alert(res.Message);
        }
      });
    },
    onIsDel(row) {
      BodycheckPaiban.UpateIsDel({
        Info: row,
      }).then((res) => {
        if (res.Code == 0) {
          this.onLoadData();
          this.msg.success(res.Message);
        } else {
          dlg.alert(res.Message);
        }
      });
    },
    onEdit(e) {
      var obj = {};
      Object.assign(obj, e);
      this.actEditPdmBodycheckPaiban(obj);
    },
    onDelete(e) {
      this.actDeletePdmBodycheckPaiban({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: e,
      }).then((res) => {
        if (res.Code == 0) {
          this.onLoadData();
        }
      });
    },
    //onExportData:{},
    //需要合计：表头必须添加 :footer-method="footerMethod" 跟 show-footer
    footerMethod({ columns, data }) {
      var list = ["PRIC"]; //需要汇总的字段
      const footerData = [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return "合计";
          }
          if (list.includes(column.property)) {
            return XEUtils.sum(data, column.property);
          }
          return null;
        }),
      ];
      return footerData;
    },
    handlePageChange({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage;
      this.tablePage.pageSize = pageSize;
      this.onLoadData();
    },
  },
};
</script>
